import * as React from "react"
import img_1 from "../images/pemalex_img_main_h.jpg"
import * as aboveFoldStyles from "./abovefold.module.css"

export default function AboveFold(props) {
    return (
        <React.Fragment>
          <div className={aboveFoldStyles.container}>
            <h1 className={`d-inline d-md-none pb-4 pb-md-0 ${aboveFoldStyles.centered}`}>Feito no Ceará.</h1>
            <img alt="Circular Fundo" data-sal="slide-up" data-sal-delay="100" data-sal-easing="ease-out-circ" data-sal-duration="1000" src={img_1} className={aboveFoldStyles.horizontal} />
            <h1 data-sal="slide-right" data-sal-delay="500" data-sal-easing="ease" data-sal-duration="2000" className={`d-none d-md-inline ${aboveFoldStyles.title}`}>{props.headerText}</h1>
          </div>
        </React.Fragment>
  

    )
  }