import * as React from "react"
import * as whyStyles from "./why.module.css"

export default function Why({ children }) {
  return (
    <div className={whyStyles.container}>
        <div className={`${whyStyles.inner} row m-5`}>
            <div className="p-3" data-sal="slide-up" data-sal-delay="400" data-sal-easing="ease-out-circ" data-sal-duration="1900">
                <h4 className={`${whyStyles.title} mb-4`}>Garantia & Qualidade</h4>
                <p className={whyStyles.text}>Nossos tecidos começam pela escolha das matérias-primas e nas máquinas têxteis de alta tecnologia</p>
            </div>
            <div className="p-3" data-sal="slide-up" data-sal-delay="400" data-sal-easing="ease-out-circ" data-sal-duration="1900">
                <h4 className={`${whyStyles.title} mb-4`}>Prontidão na Entrega</h4>
                <p className={whyStyles.text}>Agilidade na entrega local e disponibilidade de estoque de tecidos, com logística eficiente</p>
            </div>
            <div className="p-3" data-sal="slide-up" data-sal-delay="400" data-sal-easing="ease-out-circ" data-sal-duration="1900">
                <h4 className={`${whyStyles.title} mb-4`}>Desde 1974</h4>
                <p className={whyStyles.text}>Focamos na produção de malhas, atendendo principalmente ao polo de moda íntima do Ceará</p>
            </div>
        </div>
    </div>
  )
}