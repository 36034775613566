import * as React from "react"
import AboveFold from "../components/abovefold"
import Container from "../components/container"
import Copyright from "../components/copyright"
import HalfBlock from "../components/halfblock"
import Navbar from "../components/navbar"
import Footer from "../components/footer"
import Why from "../components/why"

import img_entretelas from "../images/pemalex_img_entretelas_v.jpg"
import img_malharia from "../images/pemalex_img_malharia_2_v.jpg"
import img_estamparia from "../images/pemalex_img_estamparia_v.jpg"

export default function Home() {
  return (
    <React.Fragment>
      <Navbar />
      <AboveFold headerText="Feito no Ceará."></AboveFold>
      <Why></Why>
      <Container>
        <HalfBlock position="image-right" image={img_malharia} titleText="Malharia" captionText="Tecelagem circular de malhas em diversos tipos de fibras, como poliamida e poliéster" link='/product/#first-section' />
        <HalfBlock position="image-left" image={img_estamparia} titleText="Estamparia" captionText="Nossa estamparia Amaré Design desenvolve estampas exclusivas, com impressão digital em tecidos planos e malhas" link='/product/#second-section' />
        <HalfBlock position="image-right" image={img_entretelas} titleText="Entretelas" captionText="Nossa fábrica nasceu com uma máquina de fusionar entretelas e continuamos a atender ao mercado local de entretelas" link='/product/#third-section'/>
      </Container>
      <Footer />
      <Copyright />
    </React.Fragment>
  )
}