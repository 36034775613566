import * as React from "react"
import { Link } from "gatsby"
import * as halfBlockStyles from "./halfblock.module.css"

export default function HalfBlock(props) {
    return (
        <React.Fragment>
            {props.position === 'image-left' ? 
            <div className={halfBlockStyles.container}>
                <img data-sal="slide-right" data-sal-delay="200" data-sal-easing="ease-out-circ" data-sal-duration="1500" src={props.image} alt="Fabrica" className={halfBlockStyles.image} />
                <div data-sal="slide-left" data-sal-delay="200" data-sal-easing="ease-out-circ" data-sal-duration="1500" className={halfBlockStyles.textContainerLeft}>
                    <h1 className={halfBlockStyles.title}>{props.titleText}</h1>
                    <p className={halfBlockStyles.caption}>{props.captionText}</p>
                    <Link to={props.link} className={halfBlockStyles.button}>
                    Saiba mais
                    </Link>
                </div>
            </div>         
            : <div className={halfBlockStyles.container}>
                <div data-sal="slide-right" data-sal-delay="200" data-sal-easing="ease-out-circ" data-sal-duration="1500" className={halfBlockStyles.textContainerRight}>
                    <h1 className={halfBlockStyles.title}>{props.titleText}</h1>
                    <p className={halfBlockStyles.caption}>{props.captionText}</p>
                    <Link to={props.link} className={halfBlockStyles.button}>
                    Saiba mais
                    </Link>
                </div>
                <img data-sal="slide-left" data-sal-delay="200" data-sal-easing="ease-out-circ" data-sal-duration="1500" src={props.image} alt="Fabrica" className={halfBlockStyles.image} />
            </div>    
            }
        </React.Fragment>
    )
  }